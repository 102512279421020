import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS

const Blogedit = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { id } = useParams(); // Get the 'id' from URL params
  const [blogById, setBlogById] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    title: "",
    url: "",
    introduction: "",
    content: "",
    metaDiscription: "",
    metaTitle: "",
    metaKeywords: "",
    blogId: "",
  });

  const fetchBlogsById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/list/${id}`,
        { headers: { token } }
      );
      if (response.data.success) {
        setBlogById(response.data.blogs);
      } else {
        toast.error("un authorized");
      }
    } catch (error) {
      setError("Failed to fetch data");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBlogsById(id); // Call function when 'id' from params is available
    }
  }, [id]);

  // Update `data` once `blogById` is fetched
  useEffect(() => {
    if (blogById) {
      setData({
        title: blogById.title || "",
        url: blogById.url || "",
        introduction: blogById.introduction || "",
        content: blogById.content || "",
        metaDiscription: blogById.metaDiscription || "",
        metaTitle: blogById.metaTitle || "",
        metaKeywords: blogById.metaKeywords || "",
        blogId: blogById._id || "",
      });
    }
  }, [blogById]);

  const onChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "title") {
      setData((data) => ({ ...data, [name]: value }));
      const slug = createSlug(value);
      data.url = slug; // Output: "this-is-a-blog-title"
    } else {
      setData((data) => ({ ...data, [name]: value }));
    }
  };

  const onChangeckintroHandler = (event, editor) => {
    const value = editor.getData();
    setData((prevData) => ({ ...prevData, introduction: value }));
  };

  const onChangeckcontentHandler = (event, editor) => {
    const value = editor.getData();
    setData((prevData) => ({ ...prevData, content: value }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (createSlug(data.url) !== data.url) {
      alert("URL must contain only hyphens and small alphabets.");
      return; // Exit early if validation fails
    }
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/addArticle`,
      data,
      { headers: { token } }
    );
    if (response.data.success) {
      setData({
        title: "",
        url: "",
        introduction: "",
        content: "",
        metaDiscription: "",
        metaTitle: "",
        metaKeywords: "",
        blogId: "",
      });
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  };
  function createSlug(title) {
    return title
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9\s-]/g, "") // Remove all non-alphanumeric chars except spaces and hyphens
      .trim() // Trim leading/trailing spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single one
  }

  return (
    <div>
      {error && <p>{error}</p>}
      {blogById ? (
        <div className="container mt-5">
          <button
            className="btn btn-outline-primary my-2"
            onClick={() => navigate(`/blog-management/index`)}
          >
            <i className="fa fa-list mx-1"></i>
            List Blog
          </button>
          <ToastContainer />
          <form
            onSubmit={onSubmitHandler}
            className="border p-4 rounded shadow-sm bg-light"
          >
            <h3 className="text-center mb-4">Edit Article</h3>

            {/* Title */}
            <div className="form-group mb-3">
              <label
                className="required-field"
                htmlFor="exampleFormControlInput1"
              >
                Title
              </label>
              <input
                type="text"
                name="title"
                required
                value={data.title}
                onChange={onChangeHandler}
                className="form-control"
                placeholder="Enter title"
              />
            </div>

            {/* URL */}
            <div className="form-group mb-3">
              <label
                className="required-field"
                htmlFor="exampleFormControlInput1"
              >
                Slug
              </label>
              <input
                type="text"
                required
                name="url"
                value={data.url}
                onChange={onChangeHandler}
                className="form-control"
                placeholder="Enter URL"
              />
            </div>

            {/* Introduction */}
            <div className="form-group mb-3">
              <label className="required-field" htmlFor="introduction">
                Introduction
              </label>
              <CKEditor
                name="introduction"
                onChange={onChangeckintroHandler}
                data={data.introduction}
                editor={ClassicEditor}
                config={{
                  ckfinder: {
                    uploadUrl: `${process.env.REACT_APP_API_URL}/api/user/uploadFile`,
                  },
                }}
              />
            </div>

            {/* Content */}
            <div className="form-group mb-3">
              <label htmlFor="content">Content</label>
              <CKEditor
                name="content"
                onChange={onChangeckcontentHandler}
                data={data.content}
                editor={ClassicEditor}
                config={{
                  ckfinder: {
                    uploadUrl: `${process.env.REACT_APP_API_URL}/api/user/uploadFile`,
                  },
                }}
              />
            </div>

            {/* Meta Title & Meta Keywords */}
            <div className="form-group mb-3">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="metaTitle">Meta Title</label>
                  <input
                    type="text"
                    name="metaTitle"
                    value={data.metaTitle}
                    onChange={onChangeHandler}
                    className="form-control"
                    placeholder="Enter meta title"
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="metaKeywords">Meta Keywords</label>
                  <input
                    type="text"
                    name="metaKeywords"
                    value={data.metaKeywords}
                    onChange={onChangeHandler}
                    className="form-control"
                    placeholder="Enter meta keywords"
                  />
                </div>
              </div>
            </div>

            {/* Meta Description */}
            <div className="form-group mb-3">
              <label htmlFor="metaDiscription">Meta Description</label>
              <textarea
                name="metaDiscription"
                onChange={onChangeHandler}
                value={data.metaDiscription}
                className="form-control"
                rows="4"
                placeholder="Enter meta description"
              />
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <input
                type="submit"
                className="btn btn-primary px-5 py-2"
                value="Submit"
              />
            </div>
          </form>
        </div>
      ) : (
        <p>
          Loading...
          <ToastContainer />
        </p>
      )}
    </div>
  );
};

export default Blogedit;
