import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { Storecontext } from "../../../Context/Storecontext";


const BlogDisplay = () => {
  const token = localStorage.getItem("token");
  const { slug } = useParams(); // Get the 'slug' from URL params
  const [blogById, setBlogById] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const {url} = useContext(Storecontext);

  const fetchBlogsById = async (slug) => {
    try {
      const response = await axios.get(
        `${url}/api/user/view/${slug}`,
        { headers: { token } }
      );
      if (response.data.success) {
        setBlogById(response.data.blogs);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch blog data.");
    } finally {
      setLoading(false); // Set loading to false once fetch is complete
    }
  };

  useEffect(() => {
    if (slug) {
      fetchBlogsById(slug); // Call function when 'slug' from params is available
    }
  }, [slug]);

  if (loading) {
    return <p>Loading...</p>; // Add a loading message or spinner while fetching data
  }

  if (!blogById) {
    return <p>Blog not found.</p>; // Handle case where blog data is null or not found
  }

  return (
    <div className="main-page-wrapper p0">
      <style>
          {`
            h3 {
              font-family: 'Gilroy-medium';
              font-size: 42px;
              font-weight: 700 !important;
            }
              h2 {
              font-family: 'Gilroy-medium';
              font-weight: 700 !important;
              font-size: 46px;
            }
              h4 {
             font-family: 'Gilroy-medium' !important;
              font-weight: 700 !important;
              
            }
               p{
              color:#343a40;
            }
          `}
        </style>
      <Helmet>
        
        {blogById?.metaDiscription && (
          <meta name="description" content={blogById.metaDiscription} />
        )}
        {blogById.metaTitle ? (
          <title>{blogById.metaTitle}</title>
        ) : (
          <title>{blogById.title}</title>
        )}
        {blogById?.metaKeywords && (
          <meta name="keywords" content={blogById.metaKeywords} />
        )}{" "}
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}

      {/* =============================================
          Fancy Hero Two
      ==============================================  */}
      <div className="fancy-hero-five bg-white">
      <img
          src="/images/shape/95.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="/images/shape/96.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="bg-wrapper ">
          <div className="container">
            <div className="col-lg-10 m-auto text-center">
              <h6 className="page-title">Our Blog</h6>
              <h1 className="heading">
                <span>{blogById.title}
                <img src="/images/shape/line-shape-11.svg" alt="" />
                </span>{" "}
                

              </h1>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-two */}

      {/* =====================================================
          Feature Blog One
      ===================================================== */}
      <div className="blog-page-bg">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
              <div className="post-meta">
                {/* <div className="tag">
                  {moment(blogById.createdAt).format("DD,MMM YY")}
                </div> */}
                <br />
                <span
                
                  dangerouslySetInnerHTML={{ __html: blogById.introduction }}
                />
                <span
                  dangerouslySetInnerHTML={{ __html: blogById.content }}
                />
                <br />
              </div>
              {/* /End post content  */}
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.feature-blog-one */}

      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default BlogDisplay;
