import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Storecontext } from "../../Context/Storecontext";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import './Blogshow.css'

const BlogStyle3 = () => {
  const { url} = useContext(Storecontext);
  const token=localStorage.getItem('token');
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Start with page 1
    const [totalPages, setTotalPages] = useState(1);   // Total pages
    const itemsPerpage=2;
    // Function to fetch blogs with pagination
    const fetchBlogs = async (page = 1) => {
      try {
        
        const response = await axios.get(`${url}/api/user/list`, {
          params: {
            page: page, // Pass the page number
            limit: itemsPerpage
          },
          headers: {token}
        });
  if(response.data.success){
        setBlogs(response.data.blogs);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
      }else{
       toast.error(response.data.message);
      }
      } catch (err) {
        console.log(err);
      } 
    };
  
    // Fetch blogs when the component mounts or the page changes
    useEffect(() => {
      fetchBlogs(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage,token]);
  
    // Function to handle page change
    const handlePageChange = (page) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
      }
    };
   
  return (
    <>
      {blogs.map((item, i) => (
        <div className="post-meta" key={i}>
          <div className="post">
            <h3>
              <Link to={`/blog/${item.url}`} className="title">
                {item.title}
              </Link>
            </h3>
            {/* <div className="date">{moment(item.createdAt).format("DD,MMM YY")}</div> */}

            <span 
                 style={{
                  fontSize: '18px',
                  color: 'black',
                  letterSpacing: '1.12px',
                }}
                  dangerouslySetInnerHTML={{ __html: item.introduction }}
                />
            <Link
              to={`/blog/${item.url}`}
              className="read-more d-flex justify-content-between align-items-center"
            >
              <span>Continue Reading</span>
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div>
          {/* End post */}
        </div>
        // /.post-meta
      ))}
      {/* Pagination Controls */}
      <div className="d-flex justify-content-between mt-4">
                <button
                  className="btn btn-outline-primary custom-btn"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <i className="fa fa-arrow-left"></i> Previous
                </button>

                <div className="d-flex">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      
                      className={`btn ${
                        currentPage === index + 1
                          ? "custom-btn"
                          : "btn-outline-secondary"
                      } mx-1`}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>

                <button
                  className="btn btn-outline-primary  custom-btn"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next <i className="fa fa-arrow-right"></i>
                </button>
              </div>
    </>
  );
};

export default BlogStyle3;
